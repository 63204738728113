import isEmail from './rules/isEmail'
import isNotEmpty from './rules/isNotEmpty'
import isGreaterThan from './rules/isGreaterThan'
import isChronologicalDate from './rules/isChronologicalDate'

const RULES = {
  email: isEmail,
  notEmpty: isNotEmpty,
  greaterThan: isGreaterThan,
  chronologicalDate: isChronologicalDate,
}

const validate = ({ rule, value }) => {
  const [ruleName, ruleArgument] = rule.split(':')
  const foundRule = RULES[ruleName]

  if (!foundRule) {
    throw `[Validator] Invalid rule specified for value '${value}'.`
  }

  const hasPassed = foundRule.validator(value, ruleArgument)

  return {
    hasPassed,
    error: hasPassed ? '' : foundRule.error.replace(':argument', ruleArgument),
  }
}

const validateAll = (arrayToValidate = []) => {
  let hasPassedAll = true
  const errors = {}

  arrayToValidate.forEach(objectToValidate => {
    const { hasPassed, error } = validate(objectToValidate)

    hasPassedAll = hasPassedAll && hasPassed

    if (error) {
      errors[objectToValidate.key] = error
    }
  })

  return {
    hasPassedAll,
    errors,
  }
}

export { validateAll }
export default validate
