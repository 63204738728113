const handleApiCall = async ({
  load = () => {},
  apiCall,
  setter = () => {},
  valueToSet = '',
  onSuccess = () => {},
  onFailure = () => {},
  notificationCall,
  notification,
}) => {
  load(true)
  const call = await apiCall()

  if (call.success) {
    if (valueToSet === '') {
      setter(call.data)
    } else if (valueToSet === 'previous') {
      setter((previousState) => [...previousState, ...call.data])
    } else {
      setter(call.data[valueToSet])
    }
    notification?.success
      && notificationCall({
        type: 'success',
        message: notification.success,
      })
    onSuccess(call.data)
  } else {
    notification?.error
      && notificationCall({
        type: 'error',
        message:
          `${notification.error} ${call.message}`
          || 'Something went wrong. Try again later.',
      })
    onFailure(call.data)
  }

  load(false)
}

export const handleCrud = async (crudMap, type, apiParams) => {
  const crudData = crudMap[type]
  const crudCommon = crudMap.common

  await handleApiCall({
    load: (param) =>
      crudData.loading
        ? crudData.loading.setIsLoading(param)
        : crudCommon.loading(param),
    setter: crudData.setter,
    valueToSet: crudData.valueToSet,
    apiCall: () => crudData.apiCall(apiParams),
    notificationCall: (param) => crudCommon.notification.addNotification(param),
    notification: crudData.notification,
    onSuccess: crudData.handleSuccess,
    onFailure: () => console.log('handle failure?'),
  })
}
