import { css } from '@emotion/react'
import colors from 'styles/colors'

export const email = css`
  display: block;
  color: ${colors.orange};
  margin-bottom: 1rem;
`

export const passwordExpire = css`
  margin-top: 1rem;
`

export const buttonResendOtp = css`
  display: block;
  text-align: center;
`

export const button = css`
  width: 90%;
`
