import { css } from '@emotion/react'

const WIDTH_DESKTOP = '90%'

export const input = css`
  margin-top: 0.5rem;
  display: block;
  width: ${WIDTH_DESKTOP};
  color: #fff;
`

export const button = css`
  width: ${WIDTH_DESKTOP};
`
