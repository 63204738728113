import React from 'react'
import PropTypes from 'prop-types'

import Input from 'components/Input'
import Button from 'components/Button'

import * as style from './styles'

/**
 * <Describe the EmailInput component here>
 *
 * @component
 * @usage import EmailInput from 'components/EmailInput'
 * @example
 * <Add an example of how EmailInput is used here>
 */
const EmailInput = ({ isLoading, email, setEmail, onSubmit, error, loginTitle }) => (
  <>
    <small style={{ color: '#fff', textAlign: 'center', width: '90%' }}>{loginTitle}</small>
    <Input
      placeholder='email@address.com'
      type='email'
      onChange={setEmail}
      defaultValue={email}
      error={error}
      css={style.input}
      theme='light'
    />
    <Button borderRadius='0.5rem' color='orange' css={style.button} onClick={onSubmit} isLoading={isLoading}>
      Sign-up or Login
    </Button>
  </>
)

EmailInput.propTypes = {
  isLoading: PropTypes.bool,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  loginTitle: PropTypes.string,
}

EmailInput.defaultProps = {
  isLoading: false,
  email: '',
  setEmail: () => { },
  onSubmit: () => { },
  loginTitle: 'Login or enter your email below for your instant one-time password.',
  error: '',
}

export default EmailInput
