import { isNil, isObject, isEmpty } from 'lodash'

const error = 'This cannot be empty'

const validator = (value) => {
  const isNotNil = !isNil(value)
  const isNotEmpty = !isEmpty(value)

  return isNotNil && (isObject(value) ? isNotEmpty : value.length !== 0)
}

const validationRule = {}
validationRule.validator = validator
validationRule.error = error

export default validationRule
