import { css } from '@emotion/react'

export const or = css`
  width: 90%;

  h3 {
    display: flex;
    flex-direction: row;
    color: #fff;
  }
  h3:before, h3:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }
  h3:before {
    margin-right: 10px
  }
  h3:after {
    margin-left: 10px
  }
`

export const socialButtons = css`
  display: flex;
  flex-direction: column;
  width: 90%;

  .social-login-button {
    padding: 5px 0;
    
    button {
      width: 100%;
      text-transform: none !important;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
    }
  }
`

export const googleSignin = css`
  background-color: white;
  border-color: white;
  color: #0000008A;
`
