/* eslint-disable no-control-regex */
import validate from 'lib/validator'
const error = 'The end date cannot be before the start date'

const validator = ({ start, end }) => {
  const dateNotEmptyRule = {
    rule: 'notEmpty',
    value: end,
  }

  return (
    validate(dateNotEmptyRule).hasPassed && new Date(end).getTime() >= new Date(start).getTime()
  )
}

const validationRule = {}
validationRule.validator = validator
validationRule.error = error

export default validationRule
