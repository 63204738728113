const error = 'This should be greater than :argument'

const validator = (value, ruleArgument = 0) => {
  return Number(value) > Number(ruleArgument)
}

const validationRule = {}
validationRule.validator = validator
validationRule.error = error

export default validationRule
