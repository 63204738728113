import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import Countdown from 'components/Countdown'
import OtpInput from 'react-otp-input'
import { getDifferenceInSeconds } from 'helpers/dateHelper'

import * as style from './styles'

/**
 * <Describe the OTPInput component here>
 *
 * @component
 * @usage import OTPInput from 'components/OTPInput'
 * @example
 * <Add an example of how OTPInput is used here>
 */
const OTPInput = ({
  email,
  otpTimeout,
  isLoading,
  onRequestOtp,
  otp,
  onChange,
  onSubmit,
}) => {
  const [canResendOtp, setCanResendOtp] = useState(false)

  const timerInSeconds = otpTimeout
    ? parseInt(getDifferenceInSeconds(otpTimeout, new Date()))
    : 1

  return (
    <>
      <small style={{ color: '#fff', textAlign: 'center', width: '90%' }} css={style.email}>{email}</small>
      <OtpInput
        value={otp}
        onChange={onChange}
        numInputs={6}
        isInputNum={true}
        containerStyle={{ marginBottom: '1rem' }}
        inputStyle={{
          width: '2.5rem',
          background: 'none',
          outline: 'none',
          border: '0.1rem solid white',
          color: 'white',
          borderRadius: '0.2rem',
          padding: '0.5rem 0.75rem',
          marginLeft: '0.5rem',
          boxSizing: 'border-box',
        }}
        theme='light'
      />
      <Button borderRadius='0.5rem' css={style.button} onClick={onSubmit} isLoading={isLoading}>
        Validate OTP
      </Button>
      <div css={style.passwordExpire}>
        <small style={{ color: '#fff' }}>
          Password will expire in{' '}
          <Countdown
            onComplete={() => setCanResendOtp(true)}
            time={timerInSeconds}
          />
          .
        </small>
        <Button
          type='link'
          onClick={onRequestOtp}
          isLoading={isLoading}
          isDisabled={!canResendOtp}
          css={style.buttonResendOtp}
        >
          Re-send OTP
        </Button>
      </div>
    </>
  )
}

OTPInput.propTypes = {
  isLoading: PropTypes.bool,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  onSubmit: PropTypes.func,
  error: PropTypes.object,
}

OTPInput.defaultProps = {
  isLoading: false,
  email: '',
  setEmail: () => { },
  onSubmit: () => { },
  error: {},
}

export default OTPInput
