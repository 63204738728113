import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import EmailInput from '~/components/Input/EmailInput'
import OTPInput from '~/components/Input/OTPInput'
import Button from '~/components/Button'
import validate from '~/lib/validator'
import { handleCrud } from '~/helpers/apiHelper'

import * as style from './styles'

const LoginContainer = ({
  rootStore,
  handleSuccess,
  loginTitle,
  googleSigninExtraParams,
  facebookSigninExtraParams,
  emailSigninExtraParams,
  id,
}) => {
  const { usersStore, notificationsStore } = rootStore

  const facebookLoginButton = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [otp, setOtp] = useState('')
  const [otpTimeout, setOtpTimeout] = useState(null)

  const crudMap = {
    common: {
      loading: setIsLoading,
      notification: notificationsStore,
    },
    requestOtp: {
      apiCall: usersStore.api().requestOtp,
      setter: setOtpTimeout,
      valueToSet: 'otp_expires_at',
      notification: {
        error: 'Could not request OTP.',
      },
    },
    validateOtp: {
      apiCall: usersStore.api().validateOtp,
      notification: {
        error: 'Could not validate OTP.',
      },
      handleSuccess,
    },
    facebookSignin: {
      apiCall: usersStore.api().facebookSignin,
      notification: {
        error: 'Could not signin via Facebook.',
      },
      handleSuccess,
    },
    googleSignin: {
      apiCall: usersStore.api().googleSignin,
      notification: {
        error: 'Could not signin via Google.',
      },
      handleSuccess,
    },
  }

  const handleEmailValidation = () => {
    const { hasPassed, error } = validate({ value: email, rule: 'email' })
    if (hasPassed) {
      setEmailError('')
      const requestData = {
        ...emailSigninExtraParams,
        email,
      }
      if (id) {
        requestData.referral_identifier = id
      }

      requestData.welcome_email_template_id = 'creator-welcome-email'
      handleCrud(crudMap, 'requestOtp', requestData)
    } else {
      setEmailError(error)
    }
  }

  const handleSubmit = () =>
    handleCrud(crudMap, 'validateOtp', {
      email,
      otp: Object.values(otp).join(''),
    })

  const responseFacebook = (response) => {
    if (response.accessToken) {
      const requestData = {
        ...facebookSigninExtraParams,
        app_name: 'user',
        auth_token: response.accessToken,
      }

      if (id) {
        requestData.referral_identifier = id
      }
      requestData.welcome_email_template_id = 'creator-welcome-email'
      handleCrud(crudMap, 'facebookSignin', requestData)
    }
  }

  const responseGoogle = (response) => {
    if (response.tokenId) {
      const requestData = {
        ...googleSigninExtraParams,
        app_name: 'user',
        google_token: response.tokenId,
      }

      if (id) {
        requestData.referral_identifier = id
      }
      requestData.welcome_email_template_id = 'creator-welcome-email'
      handleCrud(crudMap, 'googleSignin', requestData)
    }
  }

  return (
    <>
      {!otpTimeout ? (
        <EmailInput
          isLoading={isLoading}
          setEmail={setEmail}
          email={email}
          onSubmit={handleEmailValidation}
          error={emailError}
          loginTitle={loginTitle}
        />
      ) : (
        <OTPInput
          email={email}
          otpTimeout={otpTimeout}
          isLoading={isLoading}
          otp={otp}
          onChange={setOtp}
          onSubmit={handleSubmit}
          onRequestOtp={() =>
            handleCrud(crudMap, 'requestOtp', {
              ...emailSigninExtraParams,
              email,
            })
          }
        />
      )}

      <div css={style.or}>
        <h3>OR</h3>
      </div>

      <div css={style.socialButtons}>
        <div className='social-login-button'>
          {/* facebook button is weirdly rendered, found no other way to do it */}
          <div id='facebook-login' style={{ display: 'none' }}>
            <FacebookLogin
              ref={facebookLoginButton}
              size='small'
              buttonText='Sign-up or login with Facebook'
              appId='531706388005918'
              fields='name,email,picture'
              callback={responseFacebook}
            />
          </div>

          <Button
            justifyContent='flex-start'
            borderRadius='0.5rem'
            color='facebook'
            icon='facebookLogin'
            onClick={() => {
              document
                .getElementById('facebook-login')
                .children[0].children[0].click()
            }}
          >
            Sign-up or login with Facebook
          </Button>
        </div>

        <div className='social-login-button'>
          <GoogleLogin
            clientId='933936023976-4ntcoe0a7nv189u0ua8srotin7ef9b2u.apps.googleusercontent.com'
            buttonText='Login'
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            render={(renderProps) => (
              <Button
                justifyContent='flex-start'
                icon='googleLogin'
                borderRadius='0.5rem'
                css={style.googleSignin}
                onClick={renderProps.onClick}
                loading={renderProps.disabled}
              >
                Sign-up or login with Google
              </Button>
            )}
          />
        </div>
      </div>
    </>
  )
}

LoginContainer.propTypes = {
  rootStore: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  loginTitle: PropTypes.string,
  googleSigninExtraParams: PropTypes.string,
  facebookSigninExtraParams: PropTypes.string,
  emailSigninExtraParams: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.string,
}

LoginContainer.defaultProps = {
  loginTitle:
    'Login or enter your email below for your instant one-time password.',
  googleSigninExtraParams: null,
  facebookSigninExtraParams: null,
  emailSigninExtraParams: null,
}

export default LoginContainer
